import React from "react";
import { withSlideUp } from "../../Layouts";
import CardAndText from "../CardAndText";

const AboutUs: React.FC = () => {
  return (
    <section id="about">
      <CardAndText
        title="ABOUT US"
        titleColor="text-fuchsia-500"
        titleIcon="https://cdn.builder.io/api/v1/image/assets/TEMP/14dc42becd64cc4878997989cf63ed22b14352fde606bad0176e834c913aa406?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        text1="We are a passionate team of developers aiming to connect Web2 influencers with Web3"
        text2="Our backgrounds in technology and marketing drive our commitment to transforming creator and audience interactions. We believe the future belongs to those who embrace innovation and creativity."
        imgUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/a5946de6fa148370bcb23973a2d7339ce02d11cf42c0707cfd49c4736d12a2be?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        direction="right"
      />
      <CardAndText
        title="OUR PROJECT"
        titleColor="text-teal-300"
        titleIcon="https://cdn.builder.io/api/v1/image/assets/TEMP/7af7f21a5ebe1494ce91375c77751d1013a16b3e7a7e47806bf8f907191659f5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        text1="We connect influencers and audiences for mutual rewards"
        text2="By completing tasks set by influencers from Twitch, X and Youtube, viewers can earn rewards, fostering a vibrant ecosystem of participation and engagement. We are creating a space for creativity and relationships to flourish. Join us in the Metaverse, where every interaction matters!"
        imgUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/eda26e8631c87b6f7523f9915f37f0dbbfb02ad6c44ae95ca9507d312172338b?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        direction="left"
      />
      <CardAndText
        title="WHAT IS EURON?"
        titleColor="text-fuchsia-500"
        titleIcon="https://cdn.builder.io/api/v1/image/assets/TEMP/14dc42becd64cc4878997989cf63ed22b14352fde606bad0176e834c913aa406?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        text1="Euron represents the fusion of influencers and the Web 3 ecosystem"
        text2="Encapsulating the essence of empowerment, community, and innovation. The name derives from the combination of 'Euro' (symbolizing value and exchange) and 'On' (indicating activation and engagement)."
        imgUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/26379358352b575e871cfbcf8da161425437108ac7d3fabc9801038b4d2372ff?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        direction="right"
      />
    </section>
  );
};

export default withSlideUp(AboutUs);
