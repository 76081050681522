import React from "react";
import { withSlideUp } from "../../Layouts";
import Vector1 from "../../assets/Vector 1.png";
import Button from "../Button";

const Hero: React.FC = () => {
  return (
    <section className="relative px-px mt-12 w-full max-w-[1711px] max-md:mt-10 max-md:max-w-full">
      <div
        style={{
          backgroundImage: `url("${Vector1}")`,
        }}
        className={[
          "absolute",
          "w-[1640.5px]",
          "h-[730px]",
          "-top-[185px]",
          "-left-[103px]",
        ].join(" ")}
      />
      <div className="flex items-center px-20 max-lg:px-5 gap-5 max-lg:mt-[10px] mt-[-200px] max-lg:flex-col-reverse">
        <div className="flex flex-col w-[69%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col max-md:max-w-full">
            <h1 className="scale-y-75 -ml-20 max-lg:hidden font-[900] font-extraBold text-center text-[450px] text-neutral-900 text-opacity-50">
              EURON
            </h1>
            <div className="flex flex-col items-start ml-4 mt-[-150px] max-lg:mt-[0px]">
              <div className="flex gap-2.5 text-2xl font-medium tracking-wider text-fuchsia-500">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/14dc42becd64cc4878997989cf63ed22b14352fde606bad0176e834c913aa406?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
                  className="object-contain shrink-0 aspect-square fill-fuchsia-500 w-[18px]"
                  alt=""
                />
                <span className="basis-auto scale-y-75">A NEW ERA</span>
              </div>
              <h2 className="self-stretch scale-y-75 text-left text-7xl font-black text-white uppercase leading-[79px] w-[375px] sm:w-[500px] max-md:max-w-full max-md:text-4xl max-md:leading-[49px]">
                EURON, the future of community
              </h2>
              <p className="text-2xl text-left leading-9 text-zinc-300 w-[430px] max-lg:w-[80%] scale-y-75" >
                Streamers and fans connect in the Metaverse with EURON Tokens
                for mutual benefit.
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[40%] min-[900px]:absolute max-lg:w-[70%]	right-[30px] top-[200px]">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d4793b76c796a3efacdb674ab99fd7d6cd79a39a31f199d72a1cb583a7b0018?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
            className="object-contain grow mt-24 w-full aspect-[1.15] max-md:mt-10 max-md:max-w-full"
            alt="Euron visual representation"
          />
        </div>
      </div>
      <div className="flex gap-5 justify-center my-20 max-lg:flex-col items-center">
        <div>
          <Button
            variant="primary"
            size="desktop"
            className="flex flex-auto gap-2 justify-center items-center"
            to="https://presale.euron.io/"
          >
            <span className="self-stretch my-auto">Join Pre-Sale</span>
            
          </Button>
        </div>
        <div>
          <Button
            variant="menu"
            size="desktop"
            className="flex flex-auto gap-2 justify-center items-center"
            to="/whitepaper"
          >
            WHITEPAPER
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/015287fe155a56b991318ed0293d81e8068045521796f7a51a14dbeb464c9ea0?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
              className="object-contain shrink-0 self-stretch my-auto w-7 aspect-square"
              alt=""
            />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(Hero);
