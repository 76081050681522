import React, { useState } from "react";
import Button from "../Button";
import Logo from "../../assets/logo.png";

const Header: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  return (
    <header className="flex flex-col self-stretch w-full text-base font-extrabold text-center text-white border-b-2 border-white border-opacity-10 max-md:max-w-full z-10">
      <div className="flex flex-col justify-center items-center px-16 py-6 w-full border-b-2 border-white border-opacity-10 max-md:px-5 max-md:max-w-full">
        <div className="flex flex-wrap gap-5 justify-between w-full max-w-[1671px] max-md:max-w-full">
          <img
            loading="lazy"
            src={Logo}
            className="object-contain shrink-0 max-w-full aspect-[4.13] w-[130px] sm:w-[223px]"
            alt="Euron logo"
          />
          <nav className="flex flex-wrap gap-5 items-center self-start max-md:max-w-full">
            <Button
              variant="secondary"
              size="desktop"
              className="max-lg:hidden"
              to="/"
            >
              LAUNCH APP (COMING SOON)
            </Button>
            <Button
              variant="primary"
              size="desktop"
              className="max-lg:hidden"
              to="https://presale.euron.io/"
            >
              Join Pre-Sale
            </Button>
            <div className="inline-block relative">
              <Button
                variant="menu"
                size="desktop"
                className="flex gap-1.5 justify-center hidden max-md:flex"
                onClick={toggleDropdown}
              >
                <span className="self-stretch my-auto">MENU</span>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ddd59c71d84686a2c3f6f87b298242a90e5654bc588447d8ac3d511c2d261736?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
                  className="object-contain shrink-0 self-stretch my-auto w-7 aspect-square"
                  alt=""                  
                />
              </Button> 
              {
                isDropdownOpen && (
                  <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
                    <div className="py-1 text-left" role="none">
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" id="menu-item-0">Launch App (Coming Soon)</a>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700" role="menuitem" id="menu-item-1">Pre-Sale</a>
                    </div>
                  </div>
                )
              }             
            </div>
            {/* <Button
              variant="language"
              size="desktop"
              className="flex justify-center items-center max-lg:hidden"
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd32b7d1848fbf360b3ddc1bf99621ca67760ee305c5c80c0493e64ebc2693b5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
                className="object-contain shrink-0 self-stretch my-auto aspect-square w-[42px]"
                alt=""
              />
              EN
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1d00844563920aca601673e926a15772684ade9f3fbf47361f9e0c1bbcb25909?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
                className="object-contain shrink-0 self-stretch my-auto aspect-square w-[26px]"
                alt=""
              />
            </Button> */}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
