import React from "react";
import { withSlideUp } from "../../Layouts";
import Vector1 from "../../assets/Vector 1.png";

const Whitepaper: React.FC = () => {
  return (
    <section className="relative px-4 py-20 w-full max-w-[1711px] max-md:max-w-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      {/* Background Image */}
      <div
        style={{
          backgroundImage: `url("${Vector1}")`,
        }}
        className="absolute w-full h-full bg-cover bg-center opacity-20 top-0 left-0"
      />

      <div className="relative flex items-center px-10 max-lg:px-5 gap-5 max-lg:mt-5 mt-[-130px] max-lg:flex-col-reverse">
        <div className="flex flex-col w-full max-md:w-full">
          <div className="flex flex-col items-start">
            {/* Main Content */}
            <div className="relative max-w-4xl mx-auto bg-gray-800 bg-opacity-90 rounded-lg p-8 shadow-lg mt-16 text-left">
              <header className="mb-8">
                <h1 className="text-4xl font-bold text-gray-100">Euron Token Project</h1>
                <p className="text-lg text-gray-400 mt-2">Whitepaper - Version Q4 2024</p>
              </header>

              <div className="space-y-8 text-gray-200">
                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">1. Introduction</h2>
                  <p className="text-gray-300">
                    Welcome to the presale of Euron, a digital asset designed to empower users and fuel growth within a decentralized ecosystem. Euron stands at the intersection of blockchain innovation and community-driven development, offering an accessible entry point into a transformative financial landscape. This whitepaper outlines Euron’s tokenomics, allocation, presale structure, and referral program, providing you with essential information to join this exciting journey from the start. By participating in the Euron presale, investors have a unique opportunity to engage with a project that’s designed not only for financial growth but also for lasting impact in the decentralized world.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">2. Tokenomics and Allocation</h2>
                  <p className="text-gray-300">
                    To ensure Euron’s success, the token allocation has been meticulously planned to address every critical facet of the project’s growth and operational stability. The total supply of Euron tokens is strategically distributed to support platform operations, technological advancement, community engagement, and ecosystem development:
                  </p>
                  <ul className="list-disc list-inside text-gray-300 mt-4">
                    <li><strong>Presale and Public Sale:</strong> 25% - Reserved to provide early investors with an accessible entry and create a solid foundation for initial liquidity.</li>
                    <li><strong>Liquidity Pool:</strong> 10% - Supports stability and smooth trading across platforms, ensuring a balanced token market.</li>
                    <li><strong>Development and Technology:</strong> 20% - Allocated to fund ongoing development, innovations, and technical improvements that drive Euron’s competitive edge.</li>
                    <li><strong>Marketing and Partnerships:</strong> 10% - Used for outreach, strategic partnerships, and marketing campaigns to grow Euron’s user base and brand visibility.</li>
                    <li><strong>Founding Team:</strong> 25% - A commitment to the long-term vision, with incentives aligned to project milestones and success.</li>
                    <li><strong>Reserve for Ecosystem Growth:</strong> 10% - Dedicated to future expansion initiatives and community-driven projects, sustaining ecosystem growth.</li>
                  </ul>
                  <p className="text-gray-300 mt-4">
                    This balanced allocation ensures that all key areas are well-supported for the continued success and expansion of Euron, creating an infrastructure that’s built to thrive.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">3. Token Price</h2>
                  <p className="text-gray-300">
                    The Euron token is priced at 0.01 USDT per token, making it accessible for a wide range of participants. This introductory price is designed to attract a broad community of investors, laying a foundation for value appreciation as Euron’s ecosystem develops.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">4. Presale Details</h2>
                  <p className="text-gray-300">
                    The Euron presale is crafted with simplicity, transparency, and growth potential in mind, aiming to achieve specific milestones that underscore the project’s commitment to both early participants and long-term stability.
                  </p>
                  <ul className="list-disc list-inside text-gray-300 mt-4">
                    <li><strong>Duration:</strong> 60 days</li>
                    <li><strong>Soft Cap:</strong> 3 million USDT</li>
                    <li><strong>No Hard Cap:</strong> No maximum limit on token sales during the presale.</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">5. Referral Program</h2>
                  <p className="text-gray-300">
                    Euron’s multi-tiered referral program is a powerful tool for driving community engagement, rewarding different participant roles to incentivize growth and expansion.
                  </p>
                  <p className="text-gray-300 mt-4"><strong>Referral Roles and Bonuses:</strong></p>
                  <ul className="list-disc list-inside text-gray-300 mt-2">
                    <li><strong>Manager:</strong> 10% bonus on all token purchases made through their referral link.</li>
                    <li><strong>Influencer:</strong> 10% on direct referrals, 7.5% on second level, 2.5% on third level.</li>
                    <li><strong>Regular User:</strong> 7.5% on direct referrals, 5% on second level, 2.5% on third level.</li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">6. Token Claim Process</h2>
                  <p className="text-gray-300">
                    After the presale concludes and Euron officially launches, participants can claim their purchased tokens through a dedicated Token Claim Page on our platform. This streamlined and secure process ensures presale participants can conveniently access their tokens.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">7. Total Supply Calculation</h2>
                  <p className="text-gray-300">
                    Following the presale, the total supply of Euron tokens will be finalized based on the quantity sold during the event, helping to maintain token value and market stability post-launch.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">8. Conclusion</h2>
                  <p className="text-gray-300">
                    The Euron presale, structured with clear tokenomics and a flexible referral program, offers investors a valuable opportunity to join our ecosystem from the very beginning. By focusing on transparency, inclusivity, and sustainable growth, Euron is poised to deliver long-term value and engagement.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">For additional information</h2>
                  <p className="text-gray-300">
                    Please visit our platform.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(Whitepaper);
