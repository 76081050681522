import { Navigate, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import WhitepaperPage from "./pages/WhitepaperPage";

const AppRoutes = () => (
	<Routes>
		<Route path="/" element={<LandingPage />} />
		<Route path="/terms-of-use" element={<TermsOfUsePage />} />
		<Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
		<Route path="/whitepaper" element={<WhitepaperPage />} />
		<Route path="*" element={<Navigate to="/" />} />
	</Routes>
);

export default AppRoutes;
