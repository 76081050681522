import { FC, ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'primary' | 'secondary' | 'menu' | 'language';
  size: 'desktop' | 'mobile';
  to?: string; // React Router link
}

const Button: FC<ButtonProps> = ({ 
  variant, 
  size, 
  children, 
  className,
  to, 
  ...props 
}) => {
  const baseStyles = "font-expanded font-extrabold transition-all duration-200";
  
  const variants = {
    primary: [
      "bg-gradient text-white",
      "hover:opacity-80",
      "active:opacity-90",
      "transition-opacity duration-200"
    ].join(" "),
    secondary: [
      "relative text-white bg-white/5",
      "before:absolute before:inset-0",
      "before:p-[1px]",
      "before:rounded-[inherit]",
      "before:content-['']",
      "before:bg-[linear-gradient(90deg,#FC03F6,#7A92FF,#45FEDB)]",
      "after:absolute after:inset-[1px]",
      "after:bg-dark after:rounded-[inherit]",
      "after:content-['']",
      "isolate",
      "before:-z-10",
      "after:-z-10",
      "hover:bg-white/10",
      "active:bg-white/5",
      "transition-colors duration-200"
    ].join(" "),
    menu: [
      "bg-white/5 border border-white/10 text-white",
      "hover:bg-white/10",
      "active:bg-white/5",
      "transition-colors duration-200"
    ].join(" "),
    language: [
      "border border-white/10 text-white font-medium",
      "hover:bg-white/10",
      "active:bg-white/5",
      "transition-colors duration-200"
    ].join(" ")
  };

  const sizes = {
    desktop: {
      primary: "px-[28px] py-[20px] text-base rounded-[26px]",
      secondary: "px-[28px] py-[20px] text-base rounded-[26px]",
      menu: "px-[28px] py-[20px] text-base rounded-[26px]",
      language: "px-[28px] py-[20px] text-base rounded-[26px]"
    },
    mobile: {
      primary: "px-[28px] py-[20px] text-sm rounded-[21px]",
      secondary: "px-[28px] py-[20px] text-sm rounded-[21px]",
      menu: "px-[28px] py-[20px] text-sm rounded-[21px]",
      language: "px-[28px] py-[20px] text-sm rounded-[21px]"
    }
  };

  const combinedClasses = clsx(
    baseStyles,
    variants[variant],
    sizes[size][variant],
    className
  );

  if (to) {
    return (
      <Link to={to} className={combinedClasses} target="_blank">
        {children}
      </Link>
    );
  }

  return (
    <button className={combinedClasses} {...props}>
      {children}
    </button>
  );
};

export default Button;
