import React, { useState } from "react";
import { withSlideUp } from "../../Layouts";
import RadioButton from "../RadioButton";
import Button from "../Button";
import Card from "../Card";

const ReferralSystem: React.FC = () => {
  const referralData = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6d8a7aee60e209142fa23064638e7a69ae83da10fb94d9de3dd9920c3145fd45?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "First LEVEL",
      description: "Earn 7.5% for every user you refer directly to Euron.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f744fce78eec3cf1d7bc909afc2ce714540e75cc37d1fa4db52d0d7c822d8241?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "SECOND LEVEL",
      description: "Earn 5% from users referred by your direct referrals.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a6ba9ba653d768609e8fd7b7a1556444ac33d09fcbbb7bfcff0ee64811055b4f?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "THIRD LEVEL",
      description:
        "Earn 2.5% from users referred by your second-level referrals.",
    },
  ];

  const influencerReferralData = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/6d8a7aee60e209142fa23064638e7a69ae83da10fb94d9de3dd9920c3145fd45?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "First LEVEL",
      description: "Earn 10% for every user you refer directly to Euron.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f744fce78eec3cf1d7bc909afc2ce714540e75cc37d1fa4db52d0d7c822d8241?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "SECOND LEVEL",
      description: "Earn 7.5% from users referred by your direct referrals.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a6ba9ba653d768609e8fd7b7a1556444ac33d09fcbbb7bfcff0ee64811055b4f?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "THIRD LEVEL",
      description:
        "Earn 5% from users referred by your second-level referrals.",
    },
  ];

  const [selectedOption, setSelectedOption] = useState('option1');
  const handleChange = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <section id="referral" className="flex flex-col items-center w-full max-w-[1680px] max-md:mt-10 max-md:max-w-full px-20 max-lg:px-5">
      <div className="flex gap-2.5 mt-16 max-w-full text-2xl font-medium tracking-wider text-teal-300 max-md:mt-10">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7af7f21a5ebe1494ce91375c77751d1013a16b3e7a7e47806bf8f907191659f5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
          className="object-contain shrink-0 aspect-square fill-teal-300 w-[18px]"
          alt=""
        />
        <h4 className="text-p1">EURON REFERRAL SYSTEM</h4>
      </div>
      <h3 className="text-5xl font-black text-center text-white uppercase leading-[55px] max-w-[700px] sm:max-w-[767px] max-md:max-w-full my-4 sm:my-16">
        Euron connects you with your community in a new way
      </h3>
      <RadioButton option1="INFLUENCER" option2="NORMAL USER" selectedOption={selectedOption} handleChange={handleChange} />
      <div className="mt-8 flex sm:gap-10 max-md:flex-col items-center">
        {selectedOption === 'option1' ? influencerReferralData.map((data, index) => (
          <Card key={index} {...data} />
        )) : referralData.map((data, index) => (
          <Card key={index} {...data} />
        ))}
      </div>
      <div className="my-20">
        <Button variant="primary" size="desktop" to="https://presale.euron.io/">
          START REFERRING NOW
        </Button>
      </div>
    </section>
  );
};

export default withSlideUp(ReferralSystem);
