import React from "react";
import { withSlideUp } from "../../Layouts";
import Vector1 from "../../assets/Vector 1.png";

const PrivacyPolicy: React.FC = () => {
  return (
    <section className="relative px-4 py-20 w-full max-w-[1711px] max-md:max-w-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900">
      {/* Background Image */}
      <div
        style={{
          backgroundImage: `url("${Vector1}")`,
        }}
        className="absolute w-full h-full bg-cover bg-center opacity-20 top-0 left-0"
      />

      <div className="relative flex items-center px-10 max-lg:px-5 gap-5 max-lg:mt-5 mt-[-130px] max-lg:flex-col-reverse">
        <div className="flex flex-col w-full max-md:w-full">
          <div className="flex flex-col items-start">
            {/* Large Background Text */}
            
            {/* Main Content */}
            <div className="relative max-w-4xl mx-auto bg-gray-800 bg-opacity-90 rounded-lg p-8 shadow-lg mt-16 text-left">
              <header className="mb-8">
                <h1 className="text-4xl font-bold text-gray-100">Euron Privacy Policy</h1>
                <p className="text-lg text-gray-400 mt-2">Effective Date: May 16th, 2024</p>
              </header>

              <div className="space-y-8 text-gray-200">
                <section>
                  <p className="text-gray-300">
                    This Privacy Policy outlines how Euron ("we," "us," "our") collects, uses, and shares your personal information when you visit or use our website at https://euron.io (the "Site") or the Euron app (the "App"). By using the Site, App, or participating in the Euron token sale, you agree to this Privacy Policy.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">1. Personal Information We Collect</h2>
                  <p className="text-gray-300">
                    When you visit or use our Site and App, we may automatically collect information about your device and how you interact with our services.
                  </p>
                  <ul className="list-inside list-disc text-gray-300 pl-4">
                    <li>
                      <strong>Device Information:</strong> Includes IP address, time zone, browser type, operating system, device identifiers, and cookies.
                    </li>
                    <li>
                      <strong>Mobile Device Data:</strong> Unique device identifiers, mobile IP addresses, and other mobile-specific data.
                    </li>
                    <li>
                      <strong>Tracking Technologies:</strong> Cookies, log files, web beacons, tags, and pixels.
                    </li>
                  </ul>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">2. How We Use Your Personal Information</h2>
                  <p className="text-gray-300">
                    We utilize the collected information for purposes including but not limited to transaction processing, communication, user engagement, risk and fraud screening, service improvement, and marketing.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">3. Third-Party Disclosure</h2>
                  <p className="text-gray-300">
                    We do not sell or share your personal data with third parties except as outlined in this policy, including service providers, compliance and legal requests, and in the event of business transfers.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">4. Cookies and Tracking Preferences</h2>
                  <p className="text-gray-300">
                    We respect "Do Not Track" settings where applicable. Learn more at <a href="https://www.allaboutdnt.com" className="text-blue-500 hover:underline">https://www.allaboutdnt.com</a>.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">5. Third-Party Sites and Integrations</h2>
                  <p className="text-gray-300">
                    Our services may contain links to third-party websites not operated by us. We are not responsible for their content or privacy practices.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">6. Children's Privacy</h2>
                  <p className="text-gray-300">
                    Our services are not intended for individuals under 18. We do not knowingly collect information from minors.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">7. Data Protection and Security</h2>
                  <p className="text-gray-300">
                    We prioritize the security of your personal information with industry-standard measures. However, no system is 100% secure.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">8. Your Rights</h2>
                  <p className="text-gray-300">
                    You have rights including access, correction, deletion, objection, and restriction regarding your personal information. Please email us at <a href="mailto:contact@euron-token.com" className="text-blue-500 hover:underline">contact@euron-token.com</a> to exercise these rights.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">9. International Data Transfers</h2>
                  <p className="text-gray-300">
                    Euron’s services may involve data processing in countries outside your jurisdiction, including the United States and Canada.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">10. Data Retention</h2>
                  <p className="text-gray-300">
                    We retain personal information only as long as necessary to fulfill the purposes outlined in this policy or as required by law.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">11. Updates to This Privacy Policy</h2>
                  <p className="text-gray-300">
                    We may update this Privacy Policy periodically. Your continued use of our services after changes indicates acceptance.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold text-gray-100">12. Contact Us</h2>
                  <p className="text-gray-300">
                    For questions or to exercise your data rights, please contact us at <a href="mailto:info@euron.io" className="text-blue-500 hover:underline">info@euron.io</a>.
                  </p>
                  <p className="text-gray-300 mt-2">
                    You can also contact us with specific subjects such as "Don’t disclose my information" for restricting data sharing.
                  </p>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(PrivacyPolicy);
