import React from "react";
import { withSlideUp } from "../../Layouts";

// Add interface for props
interface CardAndTextProps {
  title?: string;
  titleColor?: string;
  titleIcon?: string;
  text1?: string;
  text2?: string;
  imgUrl?: string;
  direction?: "left" | "right";
}

const CardAndText: React.FC<CardAndTextProps> = ({
  title,
  titleColor = "text-red-500",
  titleIcon,
  text1,
  text2,
  imgUrl,
  direction = "right",
}) => {
  return (
    <section className="max-lg:mt-32 mt-48 w-full max-w-[1680px] max-md:mt-10 max-md:max-w-full px-20 max-lg:px-5">
      <div
        className={`flex max-lg:gap-14 gap-40 max-md:flex-col justify-between ${
          direction === "left" ? "flex-row-reverse" : ""
        }`}
      >
        <div className="flex flex-col w-[44%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto w-full max-md:mt-10 max-md:max-w-full">
            <div
              className={`flex gap-2.5 self-start text-2xl font-medium tracking-wider ${titleColor}`}
            >
              <img
                loading="lazy"
                src={titleIcon}
                className="object-contain shrink-0 aspect-squarew-[18px]"
                alt=""
              />
              <h5 className={`text-h5 ${titleColor}`}>{title}</h5>
            </div>
            <h4 className="mt-6 text-h2 text-left font-black text-white uppercase leading-[55px] max-md:max-w-full">
              {text1}
            </h4>
            <p className="mt-5 text-p2 text-left text-zinc-300 max-md:max-w-full">
              {text2}
            </p>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[56%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src={imgUrl}
            className="object-contain grow w-full rounded-3xl aspect-[1.24] max-md:mt-10 max-md:max-w-full"
            alt="Visual representation of our project"
          />
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(CardAndText as any);
