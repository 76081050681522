import React from "react";
import { withSlideUp } from "../../Layouts";
import imgUrl from "../../assets/Group 47.png";
import backgroundUrl from "../../assets/Group 48.png";

const EuronKeyMilestone: React.FC = () => {
  return (
    <>
      <section id="roadmap" className="flex relative flex-col items-center self-stretch px-20 max-lg:p-14 max-lg:px-5 pt-32 pb-5 mt-64 w-full min-h-[1128px]">
        <div
          className={`flex gap-2.5 self-start text-2xl font-medium tracking-wider text-teal-300`}
        >
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7af7f21a5ebe1494ce91375c77751d1013a16b3e7a7e47806bf8f907191659f5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
            className="object-contain shrink-0 aspect-squarew-[18px]"
            alt=""
          />
          <h5 className={`text-h5 text-teal-300`}>EURON’S KEY MILESTONES</h5>
        </div>
        <img loading="lazy" src={imgUrl} className="mt-10" alt="" />
        <div
          style={{
            backgroundImage: `url("${backgroundUrl}")`,
            backgroundRepeat: "no-repeat",
          }}
          className={[
            "absolute",
            "w-[1940.5px]",
            "h-[1730px]",
            "max-lg:top-[290px]",
						"top-[650px]",
          ].join(" ")}
        />
      </section>
    </>
  );
};

export default withSlideUp(EuronKeyMilestone);
