import React, { createContext, useContext, useEffect, useState } from "react";
import { IIsMobileViewProvider } from "./interface";

const IsMobileViewContext = createContext<IIsMobileViewProvider.Values>({
	isMobileView: false,
	setIsMobileView: () => {},
});

export const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const IsMobileViewProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const [isMobileView, setIsMobileView] = useState(false);

	useEffect(() => {
		function handleResize() {
			if (window.innerWidth <= 768) setIsMobileView(true);
			else setIsMobileView(false);
		}
		window.addEventListener("resize", handleResize);
	}, [setIsMobileView]);

	return (
		<IsMobileViewContext.Provider value={{ isMobileView: isMobile || isMobileView, setIsMobileView }}>
			{children}
		</IsMobileViewContext.Provider>
	);
};

export const useIsMobileView = () => {
	return useContext(IsMobileViewContext);
};

export default IsMobileViewProvider;
