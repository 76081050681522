import React from "react";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import ReferralSystem from "../../components/ReferralSystem";
import AboutUs from "../../components/AboutUs";
import EuronEmbodies from "../../components/EuronEmbodies";
import UnlockingDigitalWealth from "../../components/UnlockingDigitalWealth";
// import Testimonials from "../../components/Testimonials";
import EuronNews from "../../components/EuronNews";
import SubscribeSection from "../../components/SubscribeSection";
import Footer from "../../components/Footer";
import BuildOnceIcpEcosystem from "../../components/BuildOnIcpEcosystem";
  // import OurPartner from "../../components/OurPartner";
import EuronKeyMilestone from "../../components/EuronKeyMilestones";
import PremiunService from "../../components/PremiunService";

const Home: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col items-center bg-zinc-950">
      <Header />
      <Hero />
      <ReferralSystem />
      <AboutUs />
      <EuronEmbodies />
      <UnlockingDigitalWealth />
      <BuildOnceIcpEcosystem />
      {/* <OurPartner /> */}
      <EuronKeyMilestone />
			<PremiunService />
      {/* <Testimonials /> */}
      <EuronNews />
      <SubscribeSection />
      <Footer />
    </div>
  );
};

export default Home;
