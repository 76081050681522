import React from "react";
import TwitterIcon from "../../assets/social-icons/x.svg";
import InstagramIcon from "../../assets/social-icons/instagram.svg";
import TelegramIcon from "../../assets/social-icons/telegram.svg";
import TiktokIcon from "../../assets/social-icons/tiktok.svg";

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-col items-center self-stretch px-20 max-lg:px-5 w-full border-t-2 border-white border-opacity-10 max-md:px-5 max-md:pt-20 max-md:max-w-full">
      <div className="flex flex-col mt-10 max-md:mt-0 w-full">
        <div className="max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col">
            {/* <div className="flex flex-col w-[50%] max-md:ml-0 max-md:w-full">
									<form className="flex flex-col w-full text-xl text-gray-600 max-md:mt-10 max-md:max-w-full">
										<div className="flex flex-wrap gap-5 tracking-wider whitespace-nowrap max-md:max-w-full">
											<input
												type="text"
												placeholder="NAME"
												className="grow px-8 py-4 border border-solid bg-white bg-opacity-10 border-white border-opacity-10 rounded-[100px] w-fit max-md:px-10"
												aria-label="Name"
											/>
											<input
												type="email"
												placeholder="EMAIL@GMAIL.COM"
												className="grow px-10 py-4 border border-solid bg-white bg-opacity-10 border-white border-opacity-10 rounded-[100px] w-fit max-md:px-10"
												aria-label="Email"
											/>
										</div>
										<textarea
											placeholder="MESSAGE"
											className="px-8 py-4 h-[64px] mt-8 tracking-wider whitespace-nowrap border border-solid bg-white bg-opacity-10 border-white border-opacity-10 rounded-[100px] max-md:px-10 max-md:max-w-full"
											aria-label="Message"
										></textarea>
										<button
											type="submit"
											className="gap-2.5 self-end px-7 py-1 mt-8 text-base font-extrabold text-center rounded-3xl border border-solid bg-[linear-gradient(270deg,#44FEDA_0%,#7A92FF_50%,#FC4FCD_100%)] border-white border-opacity-10 min-h-[52px] text-zinc-950 max-md:w-[90%] max-md:mx-auto"
										>
											SEND MESSAGE
										</button>
									</form>
								</div> */}
            <div className="flex max-md: w-full w-[50%]">
              <nav className="flex flex-col w-[50%] items-center max-md:ml-0">
                <ul className="text-2xl text-zinc-300 max-md:mt-10 text-left">
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li className="mt-1">
                    <a href="#roadmap">Roadmap</a>
                  </li>
                  <li className="mt-1">
                    <a href="#referral">Referral Systems</a>
                  </li>
                  <li className="mt-1">
                    <a href="#services">Services</a>
                  </li>
                  <li className="mt-1">
                    <a href="#news">News</a>
                  </li>
                </ul>
              </nav>
              <div className="flex w-[50%] justify-center">
                <div className="flex flex-col text-2xl leading-snug text-zinc-300 max-md:mt-10 max-md:text-normal">
                  <a href="https://x.com/EuronX32247?t=DBTRt1bZFpffxplKM11lwQ&s=09" className="flex gap-3" target="_blank">
                    <img
                      loading="lazy"
                      src={TwitterIcon}
                      className="object-contain shrink-0 my-auto w-7 aspect-square"
                      alt="Twitter icon"
                    />
                    <span className="w-[108px]">Twitter (X)</span>
                  </a>
                  <a href="https://www.instagram.com/euronofficialeth/profilecard/?igsh=MThhbnpjOXdyc3pndA==" className="flex gap-3 mt-1 whitespace-nowrap" target="_blank">
                    <img
                      loading="lazy"
                      src={InstagramIcon}
                      className="object-contain shrink-0 my-auto w-7 aspect-square"
                      alt="Instagram icon"
                    />
                    <span>Instagram</span>
                  </a>
                  <a href="https://t.me/EuronCommunity" className="flex gap-3 mt-1 whitespace-nowrap" target="_blank">
                    <img
                      loading="lazy"
                      src={TelegramIcon}
                      className="object-contain shrink-0 my-auto w-7 aspect-square"
                      alt="Telegram icon"
                    />
                    <span>Telegram</span>
                  </a>
                  <a href="https://www.tiktok.com/@euronofficial?_t=8rOuOIqmOYS&_r=1" className="flex gap-3 mt-1 whitespace-nowrap" target="_blank">
                    <img
                      loading="lazy"
                      src={TiktokIcon}
                      className="object-contain shrink-0 my-auto w-7 aspect-square"
                      alt="Tiktok icon"
                    />
                    <span>Tiktok</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-18 font-black text-center text-[370px] text-neutral-900 text-opacity-50 max-md:mt-10 max-md:max-w-full max-md:text-[90px]">
        EURON
      </div>

      <div className="flex items-center justify-center space-x-6 mt-6 text-xl text-neutral-600 max-md:mt-0 max-md:text-[12px]">
        <a href="/terms-of-use" className="hover:underline">Terms of Use</a>
        <span>|</span>
        <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
      </div>
      
      <div className="flex justify-end items-end mt-6 space-x-6 text-xl text-neutral-600 max-md:mt-0 max-md:text-[12px]">
        <span>Contact us: <a href="mailto:info@euron.io" className="hover:underline">info@euron.io</a></span>
      </div>
      <div className="flex justify-end items-end mt-6 space-x-6 text-xl text-neutral-600 max-md:mt-0 max-md:text-[12px]">
        <span>©2024 EURON.IO. All rights reserved</span>
      </div>
      <div className="self-center mt-12 text-xl tracking-wider text-center uppercase text-neutral-600 max-md:mt-0 max-md:mb-5 max-md:text-[12px]">
      </div>

    </footer>
  );
};

export default Footer;
