import React from "react";
import PrivacyPolicy from "../../components/PrivacyPolicy";

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col items-center bg-zinc-950">
      <PrivacyPolicy />
    </div>
  );
};

export default PrivacyPolicyPage;
