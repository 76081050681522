import { useState } from 'react';

interface RadioSwitchProps {
  selectedOption: string;
  option1: string;
  option2: string;
  handleChange: (value: string) => void;
}

const RadioSwitch: React.FC<RadioSwitchProps> = ({ 
  selectedOption,
  option1,
  option2,
  handleChange,
}) => {


  return (
    <div className={[
      "relative",
      "flex",
      "items-center",
      "h-[50px]",
      "w-[400px]",
      "border",
      "border-white/10",
      "rounded-[30px]",
      "overflow-hidden",
    ].join(" ")}>
      {/* Option 1 */}
      <input
        type="radio"
        id="option1"
        name="options"
        className="hidden"
        checked={selectedOption === 'option1'}
        onChange={() => handleChange('option1')}
      />
      <label
        htmlFor="option1"
        className={[
          "flex-1",
          "text-center",
          "cursor-pointer",
          "rounded-[30px]",
          "relative",
          "z-10",
          "transition-all",
          "duration-500",
          "text-lg",
          "font-medium",
          selectedOption === 'option1' ? 'text-[#212121] font-bold' : 'text-[#7d7d7d]'
        ].join(" ")}
      >
        {option1}
      </label>

      {/* Option 2 */}
      <input
        type="radio"
        id="option2"
        name="options"
        className="hidden"
        checked={selectedOption === 'option2'}
        onChange={() => handleChange('option2')}
      />
      <label
        htmlFor="option2"
        className={[
          "flex-1",
          "text-center",
          "cursor-pointer",
          "rounded-[30px]",
          "relative",
          "z-10",
          "transition-all",
          "duration-500",
          "text-lg",
          "font-medium",
          selectedOption === 'option2' ? 'text-[#212121] font-bold' : 'text-[#7d7d7d]'
        ].join(" ")}
      >
        {option2}
      </label>

      {/* Sliding Background */}
      <span 
        className={[
          "absolute",
          "w-[50%]",
          "h-[100%]",
          "bg-gradient",
          "rounded-[30px]",
          "transition-all",
          "duration-400",
          "ease-[cubic-bezier(0.175,0.885,0.32,1.275)]",
          selectedOption === 'option1' ? 'left-[0px]' : 'left-[50%]'
        ].join(" ")}
      />
    </div>
  );
};

export default RadioSwitch;
