import React from "react";
import { withSlideUp } from "../../Layouts";
import CardAndText from "../CardAndText";
import Card from "../Card";

const EuronEmbodies: React.FC = () => {
  const embodiments = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/4dce20f010186b1e533216c24bbbdf30d8a65b403a7512073222c85aecaf06e4?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "Empowerment",
      description:
        "Euron is designed to empower creators by providing them with new ways to monetize their content and engage with their audiences. It enables influencers to build sustainable income streams in the decentralized landscape of Web 3.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/92c5194d40fa89ae6eb6e431e20a72d4e7ce02aa755c1fd1bdc4dcc13bc1fa98?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "COMMUNITY",
      description:
        "At its core, Euron fosters a strong sense of community. It incentivizes collaboration between influencers and their followers, creating a participatory environment where everyone can contribute and benefit.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/3d0267f28968e75ee142ee5e6b4123485240e1f980cce9a1e09f17b4b0b6e3be?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      title: "INNOVATION",
      description:
        "Euron signifies the pioneering spirit of the Metaverse. As a digital currency, it embraces cutting-edge technology and new economic models, allowing influencers and their viewers to explore and thrive in the evolving digital realm.",
    },
  ];

  return (
    <>
      <section className="flex relative flex-col items-center self-stretch px-20 max-lg:p-14 max-lg:px-5 pt-32 pb-5 mt-64 w-full min-h-[1128px]">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1fa90493d99fc0a063e0bf6818a488716835856a86847284a85010173fe087d?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
          className="object-cover absolute inset-0 size-full"
          alt=""
        />
        <div className="flex relative flex-col items-center max-w-[1680px]">
          <div className="flex gap-2.5 max-w-full text-2xl font-medium tracking-wider text-teal-300">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/7af7f21a5ebe1494ce91375c77751d1013a16b3e7a7e47806bf8f907191659f5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
              className="object-contain shrink-0 aspect-square fill-teal-300 w-[18px]"
              alt=""
            />
            <h2 className="grow shrink">EURON EMBODIES</h2>
          </div>
          <h3 className="mt-6 text-5xl font-black text-center text-white uppercase leading-[55px] max-w-[923px] max-md:w-[80%]">
            In the context of a cryptocurrency tailored for influencers, Euron
            embodies:
          </h3>
          <div className="self-stretch mt-32 max-md:mt-10">
            <div className="flex max-lg:gap-14 gap-10 max-lg:flex-col max-lg:items-center">
              {embodiments.map((embodiment, index) => (
                <Card key={index} {...embodiment} />
              ))}
            </div>
          </div>
        </div>
      </section>
      <div className="max-lg:px-7">
        <CardAndText
          title="WHAT IS THE METAVERSE?"
          titleColor="text-fuchsia-500"
          titleIcon="https://cdn.builder.io/api/v1/image/assets/TEMP/14dc42becd64cc4878997989cf63ed22b14352fde606bad0176e834c913aa406?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
          text1="The Metaverse is a virtual space combining AR, VR, and blockchain"
          text2="Users engage in a decentralized universe, socializing and working. They create avatars, own virtual property, and reflect real-world interactions. With Web 3 technologies, users gain ownership, controlling assets and identities, empowering creators and communities."
          imgUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/357b173bda00729cdfb93d934f0edcf8bfc06b565c0595b37e70b54b197308f5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
          direction="right"
        />
      </div>
    </>
  );
};

export default withSlideUp(EuronEmbodies);
