import React from "react";
import TermsOfUse from "../../components/TermsOfUse";
const TermsOfUsePage: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col items-center bg-zinc-950">
      <TermsOfUse />
    </div>
  );
};

export default TermsOfUsePage;
