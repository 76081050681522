import "antd/dist/reset.css";
import "./App.css";
import Providers from "./Providers";
import AppRoutes from "./routes";

function App() {
	return (
		<div className="App">
			<Providers>
				<AppRoutes />
			</Providers>
		</div>
	);
}

export default App;
