import { BrowserRouter } from "react-router-dom";
import IsMobileViewProvider from "./IsMobileViewProvider";

const Providers: React.FC<React.PropsWithChildren> = ({ children }) => (
	<IsMobileViewProvider>
		<BrowserRouter>{children}</BrowserRouter>
	</IsMobileViewProvider>
);

export default Providers;

export * from "./IsMobileViewProvider";
