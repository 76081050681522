import React from "react";
import { withSlideUp } from "../../Layouts";
import Button from "../Button";

const StarIcon: React.FC = () => (
  <img
    loading="lazy"
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/14dc42becd64cc4878997989cf63ed22b14352fde606bad0176e834c913aa406?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
    className="object-contain aspect-square fill-fuchsia-500 w-[18px]"
    alt="Star icon"
  />
);

interface StarAndTextProps {
  text: string;
  starCount?: number;
}

const StarAndText: React.FC<StarAndTextProps> = ({ text, starCount = 1 }) => (
  <div className="flex flex-col text-2xl leading-9 text-zinc-300 ">
    <div className="flex gap-2">
      {[...Array(starCount)].map((_, i) => (
        <StarIcon key={i} />
      ))}
    </div>
    <p className="mt-5 text-left text-p2 w-[200px] max-lg:w-[90%]">{text}</p>
  </div>
);

const SubscribeSection: React.FC = () => {
  return (
    <section className="flex relative flex-col items-center self-stretch px-20 max-lg:px-5 w-full min-h-[1128px] mt-32 max-md:px-5 max-md:pb-24 max-md:mt-10 max-md:max-w-full">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/16e12e3b1fce8e949be9f66b38e2052eab81f5e78703372473376bbddf232d84?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        className="object-cover absolute inset-0 size-full"
        alt="Background"
      />
      <div className="flex relative z-10 flex-col p-12 max-lg:p-9 w-full rounded-3xl border-2 border-solid bg-white bg-opacity-10 border-neutral-800 max-w-[1680px] ">
        <div className="flex max-lg:flex-col-reverse flex-wrap gap-5 justify-between max-lg:order-first text-7xl font-black text-white uppercase leading-[79px] max-md:max-w-full max-md:text-4xl max-md:leading-[49px]">
          <h2 className="max-md:max-w-full max-md:text-4xl max-md:leading-[49px] max:w-[800px] w-[70%] max-lg:w-[50%] text-left">
            SUBSCRIBE TO EURON'S PROGRAM
          </h2>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b96895716f0e6ca10bb807e6548336f9e267bc29f649213b8a40da7497715da6?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
            className="object-contain shrink-0 max-lg:self-start self-end aspect-square w-[85px] max-md:mt-10"
            alt="Program logo"
          />
        </div>

        <div className="mt-52 ml-5 w-full max-w-[1412px] max-md:mt-10 max-md:max-w-full">
          <div className="flex gap-5 max-lg:flex-col justify-between">
            <div className="flex gap-10 w-full max-lg:flex-col ">
              <StarAndText
                text="Fill in your name and email address."
                starCount={1}
              />
              <StarAndText
                text="Select if you're a Social Media Influencer."
                starCount={2}
              />
              <StarAndText
                text="If you're an influencer, provide your social media link."
                starCount={3}
              />
              <StarAndText
                text="Enter the OTP sent to your email."
                starCount={4}
              />
              <StarAndText
                text="Click 'Subscribe' to complete your subscription."
                starCount={5}
              />
            </div>
          </div>
        </div>

        <form className="mt-20 max-md:mt-10 max-md:max-w-full max-lg:-order-2">
          <div className="flex flex-wrap gap-5 items-start text-xl tracking-wider text-gray-600 max-md:max-w-full max-lg:flex-col max-md:text-[14px]">
            <input
              type="text"
              placeholder="NAME"
              className="px-8 py-6 max-lg:w-[80%] whitespace-nowrap border border-solid bg-white bg-opacity-10 border-white border-opacity-10 rounded-[100px] max-md:px-10 max-md:py-3"
              aria-label="Name"
            />
            <input
              type="email"
              placeholder="EMAIL@GMAIL.COM"
              className="px-8 py-6 max-lg:w-[80%] whitespace-nowrap border border-solid bg-white bg-opacity-10 border-white border-opacity-10 rounded-[100px] max-md:px-10 max-md:py-3"
              aria-label="Email"
            />
            <input
              type="tel"
              placeholder="CONTACT (OPTIONAL)"
              className="px-8 py-6 max-lg:w-[80%] grow-[1] border border-solid bg-white bg-opacity-10 border-white border-opacity-10 rounded-[100px] max-md:px-10 max-md:max-w-full max-md:py-3"
              aria-label="Contact (Optional)"
            />
          </div>
          <div className="flex max-lg:flex-col items-center gap-5 float-right self-end mt-8 max-w-full text-base font-extrabold text-center w-[415px] max-lg:w-[100%]">
            <Button
              variant="secondary"
              size="desktop"
              className="max-lg:w-[90%] "
            >
              VERIFY EMAIL
            </Button>
            <Button
              variant="primary"
              size="desktop"
              className="max-lg:w-[90%] "
            >
              SUBSCRIBE
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default withSlideUp(SubscribeSection);
