import React from "react";
import data from "./data.json";

const Card = ({ title, text }: { title: string; text: string }) => (
  <div className="flex flex-col rounded-[30px] border border-white border-opacity-10 max-h[440px] px-8 py-12">
    <h4 className="text-h4 font-bold text-white">{title}</h4>
    <p className="text-[16px] mt-6 text-white">{text}</p>
  </div>
);

const PremiunService: React.FC = () => (
  <section id="services" className="px-5 sm:px-10 lg:px-20 max-lg:-mt-32 mt-5 flex items-center flex-col">
    <div
      className={`flex items-center gap-2.5 my-3 text-2xl font-medium tracking-wider text-teal-300`}
    >
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/14dc42becd64cc4878997989cf63ed22b14352fde606bad0176e834c913aa406?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
        className="object-contain shrink-0 w-[18px] aspect-square"
        alt=""
      />
      <h5 className={`text-h5 text-fuchsia-500`}>OUR PREMIUM SERVICES</h5>
    </div>
    <h2 className="text-h2 text-white font-extrabold text-center w-full max-w-[700px] mb-10">
      DISCOVER THE VARIETY OF PREMIUM SERVICES WE OFFER TO MEET YOUR NEEDS
    </h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10 justify-center">
      {data.map((item, index) => (
        <Card key={index} title={item.title} text={item.text}></Card>
      ))}
    </div>
  </section>
);

export default PremiunService;
