import React from "react";
import { withSlideUp } from "../../Layouts";

interface NewsCardProps {
  image: string;
  date: string;
  title: string;
  tags: string[];
}

const NewsCard: React.FC<NewsCardProps> = ({ image, date, title, tags }) => (
  <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
    <img
      loading="lazy"
      src={image}
      className="object-contain grow w-full rounded-3xl aspect-[1.78] max-md:mt-10 max-md:max-w-full"
      alt={title}
    />
    <div className="flex flex-col items-start w-full font-medium text-gray-600 max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-1.5 items-center text-xl tracking-wider">
        <span className="my-4">BLOG</span>
        <span>{date}</span>
      </div>
      <h4 className="text-left text-h4 leading-10 text-white max-md:max-w-full">
        {title}
      </h4>
      {/* <div className="flex gap-3 max-w-full text-base tracking-wider text-center w-[280px]">
        {tags.map((tag, index) => (
          <div className="my-2 flex items-center text-[14px] font-thin p-3 h-2 border text-gray-700 border-gray-700 rounded-2xl">
            {tag}
          </div>
        ))}
      </div> */}
    </div>
  </div>
);

const EuronNews: React.FC = () => {
  const newsItems = [
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/8081d2ad8b06265015e20d25cb04e5e48f56ed44d69310a36215da0ec8cfdaf9?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      date: "OCTOBER 11, 2024",
      title: "EURON.io Expands Networks in the Gambling Industry",
      tags: ["LOREM IPSUM"],
    },
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/4f580966acc5b44bdb210cd74c35c077fd933b7a8fd8251f295ac95b8a2b7413?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      date: "OCTOBER 03, 2024",
      title:
        "EURON.io Exploring Support for Undervalued Sports and European Clubs",
      tags: ["LOREM IPSUM", "LOREM IPSUM"],
    },
    {
      image:
        "https://cdn.builder.io/api/v1/image/assets/TEMP/f49f09f73738f03db9962b9d1db6d6e7f84aeb51a34a9c7568f4c59029d9693f?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      date: "JULY 25, 2024",
      title: "EURON.io Expands Networks in the Gambling Industry",
      tags: ["LOREM IPSUM", "LOREM IPSUM"],
    },
  ];

  return (
    <section id="news" className="mt-60 w-full max-w-[1680px] max-md:mt-10 max-md:max-w-full px-20 max-lg:px-5">
      <div className="flex flex-wrap gap-5 justify-between w-full text-2xl font-medium tracking-wider max-w-[1680px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-2.5 text-fuchsia-500">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/14dc42becd64cc4878997989cf63ed22b14352fde606bad0176e834c913aa406?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
            className="object-contain shrink-0 aspect-square fill-fuchsia-500 w-[18px]"
            alt=""
          />
          <h2 className="basis-auto">EURON NEWS</h2>
        </div>
        {/* <a href="/" className="text-right text-white text-opacity-70">
          VIEW ALL
        </a> */}
      </div>
      <div className="mt-12 w-full max-w-[1680px] max-md:mt-10 max-md:max-w-full">
        <div className="flex items-start gap-5 max-md:flex-col">
          {newsItems.map((item, index) => (
            <NewsCard key={index} {...item} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(EuronNews);
