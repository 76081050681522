interface CardProps {
  icon: string;
  title: string;
  launch?: any;
  description: string;
}

const Card: React.FC<CardProps> = ({ icon, title, launch, description }) => (
  <div className="flex flex-col w-[33%] max-lg:w-[90%]">
    <div className="flex flex-col justify-between grow items-start px-12 py-14 w-full rounded-3xl border-2 border-solid bg-white bg-opacity-10 border-neutral-800 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <img
        loading="lazy"
        src={icon}
        className="object-contain w-20 aspect-[1.13]"
        alt=""
      />
      <div className="mt-28 flex flex-col items-start">
        {launch}
        <h3 className="text-h3 text-left font-black leading-9 text-white uppercase w-[261px]">
          {title}
        </h3>
        <p className="text-left mt-3 text-2xl leading-9 text-zinc-300">
          {description}
        </p>
      </div>
    </div>
  </div>
);

export default Card;
