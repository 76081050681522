import React from "react";
import Whitepaper from "../../components/Whitepaper";
const WhitepaperPage: React.FC = () => {
  return (
    <div className="flex overflow-hidden flex-col items-center bg-zinc-950">
      <Whitepaper />
    </div>
  );
};

export default WhitepaperPage;
