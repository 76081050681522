import React from "react";
import { withSlideUp } from "../../Layouts";
import imgUrl from "../../assets/Group 45.svg";
import backgroundImg from "../../assets/Mask group.png";

const buildOnceIcpEcosystem: React.FC = () => {
  return (
    <section className="max-lg:mt-32 mt-48 w-full max-w-[1680px] max-md:mt-10 max-md:max-w-full px-20 max-lg:px-5 relative">
      <div
        style={{
          backgroundImage: `url("${backgroundImg}")`,
        }}
        className={[
          "absolute",
          "w-[1990.5px]",
          "h-[930px]",
          "-top-[185px]",
          "-left-[113px]",
        ].join(" ")}
      />
      <div
        className={`flex max-lg:gap-14 gap-40 max-md:flex-col justify-between`}
      >
        <div className="flex flex-col w-[44%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col self-stretch my-auto w-full max-md:mt-10 max-md:max-w-full">
            <div
              className={`flex gap-2.5 self-start text-2xl font-medium tracking-wider text-teal-300`}
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/7af7f21a5ebe1494ce91375c77751d1013a16b3e7a7e47806bf8f907191659f5?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c"
                className="object-contain shrink-0 aspect-squarew-[18px]"
                alt=""
              />
              <h5 className={`text-h5 text-teal-300`}>
                BUILD ON ICP ECOSYSTEM
              </h5>
            </div>
            <h4 className="mt-6 text-h2 text-left font-black text-white uppercase leading-[55px] max-md:max-w-full">
              decentralized network to enhance internet functionality
            </h4>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[56%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src={imgUrl}
            className="object-contain grow w-full rounded-3xl aspect-[1.24] max-md:mt-10 max-md:max-w-full"
            alt="Visual representation of our project"
          />
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(buildOnceIcpEcosystem);
