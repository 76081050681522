import React from "react";
import { withSlideUp } from "../../Layouts";
import Card from "../Card";

const UnlockingDigitalWealth: React.FC = () => {
  const features = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/c24bb7ddb4c3a2cd04c4ccdb9d07308498a9b6b8d6b094726d63c94d1319f3bc?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      launch: (
        <div className="my-2 flex items-center text-[14px] font-thin p-3 h-2 border text-gray-500 border-gray-600 rounded-2xl">
           COMING IN 2024
        </div>
      ),
      title: "Revolutionary DeFi Solutions",
      description:
        "Experience a new era of financial autonomy with Euron's decentralized platform.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/52b702c709c50990e2c3c75c8ce70728bd7a4a2f425f1a0345716165a58e4da3?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      launch: (
        <div className="my-2 flex items-center text-[14px] font-thin p-3 h-2 border text-gray-500 border-gray-600 rounded-2xl">
          COMING IN 2025
        </div>
      ),
      title: "NFT Marketplace",
      description:
        "Buy, sell, and stake NFTs while earning from a tiered reward system that benefits.",
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/0f8c3a80e44b10d3d7175168fc59e26a113776581a77200c454a1d828c54fb7d?placeholderIfAbsent=true&apiKey=d591c7f6ec014be68d6da9703e10395c",
      launch: (
        <div className="my-2 flex items-center text-[14px] font-thin p-3 h-2 border text-[#45FEDB] border-[#45FEDB] rounded-2xl">
          AVAILABLE NOW
        </div>
      ),
      title: "Referral Program",
      description:
        "Enjoy enhanced earnings and special perks as you invite others to join the Euron revolution.",
    },
  ];

  return (
    <section className="mt-52 font-black text-center text-white uppercase px-20 max-lg:px-5">
      <h2 className="text-h2">UNLOCKING DIGITAL WEALTH</h2>
			<div className="flex z-10 flex-col mt-32 w-full">
        <div className="max-md:max-w-full">
          <div className="flex gap-10 max-lg:flex-col items-stretch">
            {features.map((feature, index) => (
              <Card key={index} {...feature} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withSlideUp(UnlockingDigitalWealth);
